import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import data from './data.json';


function Header(props) {
  return (
    <div id="header">
      <br />
      <div className="restaurant-name">
        <h1>{data.restaurant.name}</h1>
      </div>
      <div className="restaurant-info">
        <h4>{data.restaurant.telephone} | {data.restaurant.address}</h4>
      </div>
    </div>
  );
}

function Footer(props) {
  const currentYear = new Date().getFullYear()
  return (
    <div id="footer">
      <p>Copyright © {currentYear} Poke Bol Bar</p>
    </div>
  );
}

// function Banner(props) {
//   let text = data.banner.text_en;
//   if (props.lang === 'fr') {
//     text= data.banner.text_fr;
//   }
//   return (
//     <div className="banner">
//       <p>{text}</p>
//     </div>
//   )
// }

function Item(props) {
  return (
    <li className="item">
      <div className="item-image">
        <img src={`${props.image}`} alt="" />
      </div>
      <div className="item-info">
        <div className="item-name">
          <p>{props.name}</p>
        </div>
        <div className="item-description">
          <p>{props.description}</p>
        </div>
        <div className="item-price">
          <p>${props.price}</p>
        </div>
      </div>
    </li>
  );
}

// Build an section of items
class Section extends React.Component {
  retrieveItems() {
    //props.value == category_id
    const res = data.items.filter(i => i.category_id === this.props.value);
    return res;
  }

  retrieveCategory() {
    const res = data.categories.find(c => c.id === this.props.value);
    return res;
  }

  renderItem(i) {
    let elem = (
      <Item
        key={`${i.id}`}
        image={i.image}
        name={i.name_en}
        description={i.description_en}
        price={i.price}
      />
    );
    if (this.props.lang === 'fr') {
      elem = (
        <Item
          key={`${i.id}`}
          image={i.image}
          name={i.name_fr}
          description={i.description_fr}
          price={i.price}
        />
      );
    }
    return elem;
  }

  render() {
    const category = this.retrieveCategory();
    let header = category.name_en;
    if (this.props.lang === 'fr') {
      header = category.name_fr;
    }

    const items = this.retrieveItems(this.props.value);
    const elems = items.map((i) =>
      this.renderItem(i)
    );

    return (
      <div className="section">
        <div className="section-title">
          <h2>{header}</h2>
        </div>
        <ul className="section-items">
          {elems}
        </ul>
      </div>
    );
  }
}

class Menu extends React.Component {
  constructor(props) {
    super(props);
    const userLang = navigator.language || navigator.userLanguage;

    this.state = {
      currentLang: "en",
      otherLang: "fr"
    };

    if (userLang.slice(0, 2) === 'fr') {
      this.state = {
        currentLang: "fr",
        otherLang: "en"
      };
    }
  }

  // toggle between en and fr
  handleLang() {
    let tmp = this.state.currentLang;
    this.setState({
      currentLang: this.state.otherLang,
      otherLang: tmp
    })
  }

  render() {
    return (
      <div id="container">
        <div className="lang">
          <button className="lang-button" onClick={() => this.handleLang()}>
            {this.state.otherLang}
          </button>
        </div>
        <Header />
        {/* <Banner lang={this.state.currentLang}/> */}
        <div id="main">
          <Section value='poke' lang={this.state.currentLang}/>
          <Section value='appetizer' lang={this.state.currentLang}/>
          <Section value='beverage' lang={this.state.currentLang}/>
          <Section value='dessert' lang={this.state.currentLang}/>
          <Section value='extra' lang={this.state.currentLang}/>
        </div>
        <Footer />
      </div>
    )
  }
}


ReactDOM.render(
  <React.StrictMode>
    <Menu />
  </React.StrictMode>,
  document.getElementById('root')
);